.verticalLine {
  width: 3px;
  text-align: center;
  border-radius: 5px;
}

.actionSuccess {
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  gap: 16px;
}
