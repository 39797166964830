.buttonStyle {
  border: 1px solid var(--primary-black);
  width: fit-content;
  padding: 16px;
  border-radius: 5px;
  background-color: transparent;
  -webkit-appearance: none;

  p {
    color: var(--primary-black);
  }
}

.disabled {
  cursor: not-allowed;
}

.active:hover {
  background-color: #333;
  div {
    p {
      color: white !important;
    }
    .icon {
      color: white;
    }
  }
}

.noStyleButton {
  border: none;
  background-color: transparent;
  color: #333 !important;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.buttonStyle:hover {
  background-color: #333 !important;
  color: white;

  p {
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .buttonStyle:hover {
    background-color: #333 !important;
    color: white;
  }
}
