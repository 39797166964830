.scrollView {
  overflow-x: overlay;
  overflow-y: visible;
  display: grid;
  grid-auto-columns: minmax(230px, 230px);
  grid-auto-flow: column;
  padding: 0px 8px;
  padding-bottom: 16px;
  padding-top: 8px;
  gap: 16px;
}

.inputField {
  position: relative;
  max-width: 400px;
}

.addBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin-right: 8px;
}

.headerView {
  padding-right: 8px;
  padding-bottom: 16px;
  padding-top: 8px;
}

.buttonField {
  max-width: 830px;
}

.compareView {
  margin-top: 24px;
  width: 100%;
  display: flex;
}

.cardGridView {
  display: grid;
  grid-template-columns: auto 1fr;
  padding-bottom: 50px;
  width: 1100px;
}

.horizontalLineShort {
  width: 13px;
  height: 1px;
  background-color: #05426a;
}

@media screen and (max-width: 830px) {
  .scrollView {
    grid-auto-columns: minmax(170px, 170px);
  }

  .cardGridView {
    max-width: 95.5vw;
    width: 95.5vw;
  }
}
