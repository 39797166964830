.select-search.has-focus .select-search__select {
  display: block;
  position: absolute;
  z-index: 210;
  background-color: #fff;
  border: solid var(--light-blue) 4px;
  margin-top: 5px;
  text-align: left;
  width: 100%;
  overflow: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.select-search__select {
  display: none;
}

.select-search__options {
  max-height: 200px;
  margin-bottom: 1px;
  padding: 10px !important;
  list-style: none;
  cursor: pointer;
  margin-top: 0px;
}

.select-search__row {
  padding: 0;
  border-bottom: solid 1px var(--light-blue);
  cursor: pointer;
}

.select-search__option {
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  padding: 5px 0;
  font-family: var(--font-family-book);
}

.select-search__option.is-highlighted {
  background-color: var(--light-blue);
}

.select-search__option:hover,
.select-search__option:focus {
  background-color: var(--light-blue);
  -moz-appearance: none;
  -webkit-appearance: none;
}

.select-search__option.is-selected {
  background-color: #5bc0de !important;
}

.select-search__option.is-selected.is-highlighted {
  background-color: #008cba !important;
}

.select-search {
  position: relative;
}

.select-search #municipality {
  display: block;
  font-family: var(--font-family-book);
  width: 100%;
  background-image: none;
  border-radius: var(--input-border-radius);
  outline-offset: 0px;
  background-color: var(--light-blue);
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-black);
  line-height: 1.5;
  box-shadow: 0;
  border: 0;
  padding: 0.5em 1.4em 0.5em 0.8em;
  margin: 0;
  height: fit-content;
}

#municipality:focus {
  outline-offset: 0px;
  -moz-appearance: none;
  -webkit-appearance: none;
}
