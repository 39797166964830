.newsStyle {
  font-family: var(--font-family-news);
}

.paragraph {
  margin: 0;
  width: fit-content;
  color: inherit;
  line-height: 1.5em;
}

.withPreBreak {
  white-space: pre-wrap;
}

.error {
  color: var(--color-error);
}

.dashed {
  text-decoration-style: dashed !important;
  text-decoration-thickness: 1px !important;
}

@media screen and (max-width: 420px) {
  .breakAllOnPhone {
    word-break: break-all !important;
  }
}
