.card {
  box-shadow: 0px 2px 8px 0px #00000040;
  border: 0.5px solid #e2e7e9;
  padding: 16px;
  width: 240px;
  display: grid;
  grid-template-rows: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.currentProductCard {
  background-color: #05426a;
  color: white !important;
  grid-template-rows: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  div {
    p {
      color: white;
    }
  }
}

.cardButton {
  border-radius: 5px;
  border: 1px solid var(--primary-black);
  padding: 8px;
  width: fit-content;
  color: var(--primary-dark-blue);
  display: flex;
}

.cardButton:hover {
  background-color: var(--primary-black);

  div {
    p {
      color: var(--primary-white);
    }
    .icon {
      color: var(--primary-white);
    }
  }
  cursor: pointer;
}

@media screen and (max-width: 830px) {
  .card {
    width: 192px;
  }
}
