.border {
  border: 1px solid #333333;
  padding: 16px;
  width: 100%;
}

.marginBottom {
  margin-bottom: 8px;
}

.margin {
  margin-top: 8px;
}

.errorField {
  padding: 8px;
  width: fit-content;
  color: #d94740;
}

.errorBorder {
  border: 2px solid #d94740;
}

.noStyleButton {
  background-color: transparent;
  border: none;
}
