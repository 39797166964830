.condition {
  margin-top: 16px;
}

.conditionRows {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tableRow {
  display: grid;
  grid-template-columns: 1fr auto;

  span {
    font-family: var(--font-family-news);
    text-align: right;
    white-space: wrap;
  }

  > span:first-child {
    font-family: var(--font-family-book);
    text-align: left;
  }
}

.backButton {
  text-decoration: underline;
  cursor: pointer;
}

.conditionHeaderText {
  font-family: var(--font-family-book);
}

.otherConditions {
  max-width: 50%;
}
