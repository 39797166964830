.link {
  color: #05426a;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  white-space: nowrap;
}

.header {
  p {
    max-width: 700px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .link {
    white-space: normal;
  }
}
