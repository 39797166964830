.expandContentButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: auto;
  border: none;
  background-color: transparent;
  color: #333 !important;
}

.arrowIcon {
  height: 16px;
  width: 16px;
}

.text {
  line-height: 16px;
}
