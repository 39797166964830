
.priceTable {
  width: 100%;
  border-spacing: 4px;
  border: 4px solid var(--secondary-gray-30);

  th {
    text-align: left;
  }

  td,
  th {
    padding: 1rem 0.5rem;
    margin: 4px;
    cursor: pointer;
    position: relative;

    @media screen and (max-width: 830px) {
      padding: 0.5rem 0px;
    }
  }

  tr:nth-child(odd) {
    background-color: var(--secondary-gray-30);
  }

  tr:nth-child(even) {
    background-color: var(--secondary-gray-30);
  }
}

.active {
  background-color: var(--secondary-light-blue-20-opaque-50) !important;
}

.activeMonth {
  font-style: italic;
  background-color: var(--secondary-light-blue-10);
}

.caption {
  caption-side: bottom;
  text-align: left;
  font-style: italic;
  max-width: 80%;
  margin-bottom: 1rem;
  margin-top: 8px;
}
