.news {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.h2 {
  margin: 0;
  margin-top: 10px;
  line-height: 2rem;
  font-size: 1.5rem !important;
}

.p {
  margin-top: 10px;
}

@media screen and (max-width: 830px) {
  .news {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media screen and (max-width: 550px) {
  .news {
    grid-template-columns: 1fr !important;
  }
}
