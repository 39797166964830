.compareProductsContainer {
  background-color: var(--secondary-gray-30);
  padding: 0px 16px 0px 24px;
  margin-top: 24px;
  max-height: 0px;
  transition: all 0.1s ease-out;
  overflow: hidden;
}

.toggledContainer {
  max-height: 300px;
  padding: 8px 16px 8px 24px;
  transition: all 0.1s ease-in;
}

.noStyleButton {
  border: none;
  background-color: transparent;
}
