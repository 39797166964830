.buttonView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-family-news);
  margin-top: 28px;

  p {
    cursor: pointer;
  }
}

.buttonWithTextAndArrow {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.orderButton {
  background: white;
  border: 1px solid var(--product-color-primary);
}

.goBackButton {
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  color: #333 !important;
}

@media screen and (min-width: 600px) {
  .buttonView {
    padding-left: 20px;
  }
}
