.h2 {
  margin: 0;
  margin-top: 10px;
  line-height: 2rem;
  font-size: 1.7rem !important;
}

.p {
  margin-top: 10px;
}
