.headerTextContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.headerText {
  color: #05426a;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
}

.text {
  width: fit-content;
}

.icon {
  height: 23.25px;
  width: 23.25px;
}

.labelSpan {
  cursor: pointer;
}

@media only screen and (max-width: 830px) {
  .headerText {
    white-space: normal;
  }

  .icon {
    height: 19.38px;
    width: 19.38px;
  }
}
