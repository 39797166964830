.wordGrid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-around;
  max-width: 1100px;
  gap: 2rem;
}

.gridItem {
  padding: 1rem;
  border: 1px solid var(--secondary-gray-30-no-opacity);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allWordsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.gridAllItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .wordGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .allWordsGrid {
    grid-template-columns: 1fr 1fr;
  }
}
