.tooltip {
  color: #05426a;
  font-family: var(--font-family-news);
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  line-height: 1.5rem;
  cursor: pointer;
}

.periodSelect {
  max-width: 50%;
}

.textInput {
  min-width: 150px;
  width: fit-content;
  max-width: 320px;
}

@media screen and (max-width: 830px) {
  .textInput {
    width: 100%;
  }
}
