:root {
  --underline-color: hsl(183, 65%, 53%);
  --light-blue: hsl(188, 62%, 89%);
  --light-yellow: #fdedd3;
  --color-focus-outline: hsl(189, 63%, 56%);
  --dark-blue: hsl(189, 100%, 40%);
  --background-light-gray: #f3f3f3;

  --primary-dark-blue: #05426a;
  --primary-light-blue: #00abc4;
  --primary-white: #ffffff;
  --primary-black: #333333;

  --secondary-light-blue-100: #38ced5;
  --secondary-light-blue-80: #60d8dd;
  --secondary-light-blue-60: #88e2e6;
  --secondary-light-blue-40: #afebee;
  --secondary-light-blue-20: #d7f5f7;
  --secondary-light-blue-10: #ebfafb;
  --secondary-light-blue-20-opaque-50: #d7f5f7cd;

  --secondary-green-100: #00955e;
  --secondary-green-80: #33aa7e;
  --secondary-green-60: #66bf9e;
  --secondary-green-40: #99d5bf;
  --secondary-green-20: #cceadf;
  --secondary-green-10: #e5f4ef;
  --secondary-green-20-opaque-30: #cceadf4d;

  --color-month-view: #cceade4d;

  --secondary-orange-100: #f6a624;
  --secondary-orange-80: #f8b850;
  --secondary-orange-60: #faca7c;
  --secondary-orange-40: #fbdba7;
  --secondary-orange-20: #fdedd3;
  --secondary-orange-10: #fef6e9;

  --secondary-turquoise-100: #004957;
  --secondary-turquoise-80: #336d79;
  --secondary-turquoise-60: #66929a;
  --secondary-turquoise-40: #99b6bc;
  --secondary-turquoise-20: #ccdbdd;
  --secondary-turquoise-10: #e5edee;

  --secondary-gray-30: #e2e7e94d;
  --secondary-gray-30-no-opacity: #e2e7e9;

  --color-modal-overlay-background: hsl(157, 42%, 94%);
  --color-modal-header-background: rgb(205, 233, 222);
  --color-modal-footer-background: hsl(156, 39%, 86%);
  --color-modal-content-background: white;
  --color-modal-border: hsl(154, 39%, 73%);

  --overpris-good: hsl(154, 100%, 29%);
  --overpris-ok: hsl(41, 90%, 55%);
  --overpris-bad: hsl(15, 75%, 52%);
  --highlight: hsl(41, 90%, 55%);
  --gridrent-text-color: hsla(0, 0%, 0%, 0.45);
  --gridrent-icon-color: hsl(15, 74%, 62%);

  --primary-link: #05426A;

  --color-info: hsl(183, 66%, 72%);
  --color-warning: hsl(40, 77%, 64%);
  --color-error: hsl(349, 99%, 35%);

  --spot-color: #05426a;
  --spot-color-light: var(--secondary-green-10);
  --spot-color-dark: var(--secondary-green-60);
  --spot-color-opaque: var(--secondary-green-20-opaque-30);

  --fixed-color: hsl(188, 48%, 79%);
  --fixed-color-light: hsl(187, 60%, 97%);
  --fixed-color-dark: hsl(186, 100%, 39%);
  --fixed-color-opaque: hsl(188, 48%, 79%, 0.3);

  --plus-color: hsl(37, 86%, 83%);

  --custom-color: hsl(195, 15%, 90%);
  --custom-color-light: hsl(0, 0%, 96%);
  --custom-color-dark: hsl(0, 83%, 57%);
  --custom-color-opaque: hsl(195, 15%, 90%, 0.3);

  --font-family-book: "ClanOT-Book", sans-serif;
  --font-family-news: "ClanOT-News", sans-serif;

  --input-height-base: 42px;
  --padding-base-vertical: 8px;
  --padding-base-horizontal: 12px;
  --font-size-input: 1rem;
  --line-height-base: 1.5;
  --input-color: hsl(0, 0%, 0%);
  --input-bg: var(--light-blue);
  --border-radius-base: 0;
  --input-border-radius: var(--bodrder-radius-base);
}

*:focus-visible {
  outline: 4px solid var(--color-focus-outline);
  outline-offset: 8px;
}
iframe {
  pointer-events: none;
}

button {
  cursor: pointer !important;
  font-family: var(--font-family-news);
}

button:focus-visible {
  outline: 4px solid var(--color-focus-outline) !important;
  outline-offset: 8px !important;
}

a {
  cursor: pointer !important;
}

a:focus-visible {
  outline: 4px solid var(--color-focus-outline) !important;
  outline-offset: 8px !important;
}

button,
button::-moz-focus-outer,
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="button"]::-moz-focus-outer,
input[type="submit"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-outer,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer !important;
  font-family: var(--font-family-news);
  outline: 4px solid var(--color-focus-outline);
  outline-offset: 8px;
}

html,
body,
#root,
#root > div {
  height: 100vh;
  height: -webkit-fill-available;
  /* height: fill-available; */
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-book);
}

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-base);
}

p {
  font-family: var(--font-family-book);
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-black) !important;
  font-weight: 300;
}

h1 {
  font-size: 2.625rem !important;
}

h2 {
  font-size: 2rem !important;
}

h3 {
  font-size: 1.375rem !important;
  font-family: var(--font-family-news);
}

h4 {
  font-size: 1.125rem !important;
  font-family: var(--font-family-news);
}

body {
  font-size: 1rem !important;
}

@media screen and (max-width: 830px) {
  h1 {
    font-size: 2rem !important;
  }

  h2 {
    font-size: 1.375rem !important;
  }

  h3 {
    font-size: 1.125rem !important;
  }

  h4 {
    font-size: 1rem !important;
  }
  body {
    font-size: 0.875rem !important;
  }
}

h1,
h2,
h4,
small {
  font-family: var(--font-family-news);
}

a {
  color: var(--primary-black);
}

@font-face {
  font-family: "ClanOT-Book";
  src: local("ClanOT-Book"), url(./fonts/ClanOT-Book.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "ClanOT-News";
  src: local("ClanOT-News"), url(./fonts/ClanOT-News.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "ClanOT-Bold";
  src: local("ClanOT-Bold"), url(./fonts/ClanOT-Bold.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "ClanOT-Medium";
  src: local("ClanOT-Medium"), url(./fonts/ClanOT-Medium.woff) format("woff");
  font-display: swap;
}

.overlay {
  position: absolute;
  text-align: center;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);
}

.overlay-menu {
  z-index: 300;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.fadeIn {
  animation-name: fadeIn;
}

.underline-title {
  float: left;
  border-bottom-color: var(--underline-color);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  width: 100%;
  margin-top: 29px;
  margin-bottom: 18px;
  padding-bottom: 11px;
  font-size: 1.125rem;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

/* class applies to select element itself, not a wrapper element */
.blue-input {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 1.5;
  min-height: 40px;
  width: 100%;
  background-color: var(--light-blue);
  margin: 0 auto;
  box-shadow: none;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0.5em 1.4em 0.5em 0.8em;
  font-family: var(--font-family-book);
}

.blue-select {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 1.5;
  min-height: 40px;
  height: fit-content;
  width: 100%;
  background-color: var(--light-blue);
  margin: 0 auto;
  box-shadow: none;
  border: 0;
  font-family: var(--font-family-book);
}

.select-search__option {
  color: var(--primary-black);
}

.select-css {
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  appearance: none;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}

.ReactModal__Content {
  padding: 0px !important;
}
.__react_component_tooltip {
  max-width: 70vw;
}

/* .ReactModal__Body--open {
  position: static;
  overflow-y: scroll;
}

.ReactModal__Html--open {
  overflow-y: hidden;
}
.ReactModalPortal {
  position: fixed;
  z-index: 1 !important;
}
 */
