.informationChip {
  border: 1px solid #dfdfdf;
  border-radius: 5rem;
  padding: 8px 12px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.severe {
  border-color: #d94740;
}
