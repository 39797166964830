.card {
  box-shadow: 0px 2px 8px 0px #00000040;
  border: 0.5px solid #e2e7e9;
  padding: 16px;
  width: 240px;
  display: grid;
  height: 100%;
  grid-template-rows: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 830px) {
  .card {
    width: 160px;
  }
}
