.showDetailsButton {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  -webkit-appearance: none;
  margin: 0;
  color: #333 !important;
}

.calculations {
  max-width: 500px;
}

.linkStyle {
  color: #05426a;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
}

.calculationContainer {
  background-color: #e2e7e94d;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.errorBox {
  border: 2px solid var(--color-error);
  padding: 8px;
  width: 100%;
}

.showDetailsButton {
  border: none;
  background-color: transparent;
}

.horizontalLine {
  border-bottom: 1px solid var(--primary-black);
  margin-top: 2px;
  width: 100%;
}

.verticalLine {
  border-left: 1px solid var(--primary-black);
}

.monthLayout {
  overflow-x: auto;
  background-color: var(--color-month-view);
  padding: 16px;
}

.invisible {
  border: 1px solid var(--color-month-view);
}
