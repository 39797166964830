.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.__react_component_tooltip {
  background-color: #fff !important;
  box-shadow: 0px 2px 8px 0px #00000040 !important;
  border-radius: 5px !important;
  border: 1px solid #e2e7e9 !important;
  padding: 16px !important;
  pointer-events: auto !important;
  color: #333333 !important;
  width: auto !important;
  max-width: 500px !important;
  opacity: 1 !important;
}

.MuiTooltip-popper {
  background-color: #fff Im !important;
}

@media screen and (max-width: 600px) {
  .__react_component_tooltip {
    max-width: 350px !important;
  }
}
