.toggleContainer {
  width: 70px;
  height: 42px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  background-color: #e2e7e9;
  padding: 5px;
  border: none;
  position: relative;
  cursor: pointer;
}

.toggleHandle {
  background-color: white;
  height: 32px;
  width: 32px;
  position: absolute;
  border-radius: 50%;
  left: 0;
  display: flex;
  align-items: center;
  margin-left: 5px;
  transition: all 0.1s ease;
  justify-content: center;
}

.toggled {
  transform: translateX(100%);
  transition: all 0.1s ease;
  margin-left: -1px;
}
