.gridLayout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0 0;
  font-size: clamp(0.875rem, 2.5vw, 1rem);
  max-width: 700px;
  margin: 0 auto;
  background-color: var(--product-color-primary);
  border-bottom: 1px solid var(--product-color-secondary);
  position: sticky;
  padding: 32px;
  gap: 15px;
  z-index: 100;
}

@media only screen and (max-width: 830px) {
  .gridLayout {
    padding-right: 4px;
    padding-left: 4px;
  }
}
