.cardLayout {
  display: flex;
  flex-direction: column;
  border: 1px solid #f3f3f3;
  padding: 32px;
}

.cardLayout:hover {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
}

.cardHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  gap: 15px;
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr auto;
}

.informationView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.marginTop {
  margin-top: 16px;
}
.mobileCardStyle {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
  padding: 16px;
}

.mobileCardStyle:hover {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
}

@media screen and (max-width: 830px) {
  .totalPrice {
    text-align: center;
  }
}
