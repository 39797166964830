.tooltip {
  color: #05426a;
  font-family: var(--font-family-news);
  text-decoration: underline;
  cursor: pointer;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
}

.moveLeft {
  ul {
    padding-left: 16px;
  }
}

.bold {
  font-family: var(--font-family-news);
}
