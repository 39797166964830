.icon {
  height: 24px;
  width: 24px;
}

.compareProductsHeader {
  padding: 8px;
  background-color: var(--secondary-gray-30);
  cursor: pointer;
}

.addedProduct {
  background-color: var(--secondary-green-10);
}

.limitReach {
  background-color: var(--background-light-gray);
  cursor: not-allowed;
}
