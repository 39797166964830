.title {
  font-family: var(--font-family-news);
  font-size: 1rem;
  font-weight: 475;
  line-height: 1.5em;
  font-style: normal;
}

.tooltipText {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #333333;
}

.info {
  word-wrap: normal;
  word-break: keep-all;
  font-size: 1rem;
  line-height: 1.5em;
  font-family: var(--font-family-book);
  text-align: left;
}

.tooltip {
  pointer-events: auto !important;
}
